*,
*::before,
*::after {
    box-sizing: border-box;
    line-height: 1.5;
    backface-visibility: hidden;
}

:root {
    font-variant-ligatures: common-ligatures;
    text-rendering: optimizeLegibility;
    font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
}

button,
input,
textarea,
label {
    cursor: pointer;
}

[hidden] {
    display: none;
}

::selection {
    background: rgba(45, 170, 219, 0.3);
}

body {
    margin: 0;
    padding-top: 108px;
    color: rgb(55, 53, 47);
    font-size: 16px;
    padding-bottom: 30vh;
}

body > :not(table):not(blockquote):not(img):not(pre):not(main),
main > :not(table):not(blockquote):not(img):not(pre) {
    padding-left: 16px;
    padding-right: 16px;
    margin: 0 auto;
    max-width: 708px;
}

header .icon {
    display: inline-block;
    content: attr(data-icon);
    font-size: 78px;
}

header .background {
    height: 250px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

header .background + .icon {
    margin-top: 84px;
}

header .background + h1 {
    margin-top: 175px;
}

h1 {
    margin: 0 auto;
    font-weight: 600;
    font-size: 1.875em;
    line-height: 1.3;
    padding-top: 2em;
    padding-bottom: 7px;
}

header h1 {
    margin: 0 auto;
    font-weight: 700;
    line-height: 1.2;
    font-size: 40px;
    padding-bottom: 23px;
    padding-top: 20px;
}

h2 {
    margin: 0 auto;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.3;
    padding-top: 1.4em;
    padding-bottom: 4px;
}

h3 {
    margin: 0 auto;
    font-weight: 600;
    font-size: 1.25em;
    line-height: 1.3;
    padding-top: 1em;
    padding-bottom: 4px;
}

p {
    word-break: break-word;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

blockquote {
    border-left: 3px solid;
    padding-left: 13px;
    padding-right: 0.9em;
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 1.2em;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    max-width: 676px;
}

ol {
    list-style-position: inside; 
    padding-left: 22px !important;
}

ul {
    list-style-position: inside;
    padding-left: 25px !important;
}

li {
    padding-top: 3px;
    padding-bottom: 3px;
}

li::before {
    content: '';
    display: inline;
    padding-left: 5px;
}

hr {
    border: none;
    padding: 2px 0;
    padding-top: 7px;
    border-bottom: 1px solid rgba(55, 53, 47, 0.09);
    max-width: 676px !important;
}

.table-wrapper {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
}

table {
    min-width: 100%;
    font-size: 14px;
    border-collapse: collapse;
    margin-top: 4px;
    border-top: 1px solid rgba(55, 53, 47, 0.16);
    overflow-y: auto;
    margin-bottom: 38px;
}

table caption {
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    padding-top: 6px;
    padding-bottom: 9px;
}

td {
    border-bottom: 1px solid rgba(55, 53, 47, 0.16);
    padding: 5px 8px 6px;
    min-width: 100px;
}

td:not(:last-child) {
    border-right: 1px solid rgba(55, 53, 47, 0.16);
}

th {
    text-align: left;
    border-bottom: 1px solid rgba(55, 53, 47, 0.16);
    padding: 5px 8px 6px;
    font-weight: normal;
    color: rgba(55, 53, 47, 0.6);
    min-width: 100px;
}

th:not(:last-child) {
    border-right: 1px solid rgba(55, 53, 47, 0.16);
}

td > [class^='b-'],
th > [class^='b-'] {
    line-height: 1.5;
    border-radius: 3px;
    padding: 0.1em 0.4em;
    white-space: nowrap;
}

code {
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    line-height: normal;
    background: rgba(135,131,120,0.15);
    color: #EB5757;
    border-radius: 3px;
    font-size: 85%;
    padding: 0.2em 0.4em;
}

pre {
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    line-height: normal;
    border-radius: 3px;
    font-size: 85%;
    padding: 30px 16px;
    margin: 16px auto;
    max-width: 676px;
    background: rgb(247, 246, 243) none repeat scroll 0% 0%;
}

a {
    color: inherit;
    opacity: 0.7;
    transition: opacity 100ms ease-in;
    text-decoration: none;
    background: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1" height="1"%3E%3Crect x="0" y="0" width="1" height="0.3" fill="rgb(55, 53, 47)"/%3E%3C/svg%3E') left bottom repeat-x;
}

a:hover {
    opacity: 1;
}

img {
    display: block;
    margin: 0.5em auto;
    height: auto;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 100%;
}

.c-gray {
    color: rgba(55, 53, 47, 0.6);
}

.b-gray {
    background: rgb(235, 236, 237) none repeat scroll 0% 0%;
}

.c-brown {
    color: rgb(100, 71, 58);
}

.b-brown {
    background: rgb(233, 229, 227) none repeat scroll 0% 0%;
}

.c-orange {
    color: rgb(217, 115, 13);
}

.b-orange {
    background: rgb(250, 235, 221) none repeat scroll 0% 0%;
}

.c-yellow {
    color: rgb(223, 171, 1);
}

.b-yellow {
    background: rgb(251, 243, 219) none repeat scroll 0% 0%;
}

.c-green {
    color: rgb(15, 123, 108);
}

.b-green {
    background: rgb(221, 237, 234) none repeat scroll 0% 0%;
}

.c-blue {
    color: rgb(11, 110, 153);
}

.b-blue {
    background: rgb(221, 235, 241) none repeat scroll 0% 0%;
}

.c-purple {
    color: rgb(105, 64, 165);
}

.b-purple {
    background: rgb(234, 228, 242) none repeat scroll 0% 0%;
}

.c-pink {
    color: rgb(173, 26, 114);
}

.b-pink {
    background: rgb(244, 223, 235) none repeat scroll 0% 0%;
}

.c-red {
    color: rgb(224, 62, 62);
}

.b-red {
    background: rgb(251, 228, 228) none repeat scroll 0% 0%;
}

